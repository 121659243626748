@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-light-background-default dark:bg-dark-background-default text-light-text-primary dark:text-dark-text-primary;
  }
  .flex-basis-0 {
    flex-basis: 0%;
  }
  .flex-basis-auto {
    flex-basis: auto;
  }
  .abs-w-0 {
    @apply w-0;
    max-width: 0px;
    min-width: 0px;
  }
  .abs-w-16 {
    @apply w-16;
    max-width: 64px;
    min-width: 64px;
  }
  .abs-w-64 {
    @apply w-64;
    max-width: 256px;
    min-width: 256px;
  }
  .max-w-collapsed {
    max-width: calc(100% - 64px);
  }
  .max-w-expanded {
    max-width: calc(100% - 256px);
  }
  .max-w-content-lg {
    max-width: 1200px;
  }
  .max-w-content-xl {
    max-width: 1536px;
  }
  .max-w-content-full {
    max-width: 100vw;
  }

  .basic-grid-container {
    @apply grid grid-cols-4 gap-4 md:gap-6 sm:grid-cols-12 pt-6 md:pt-8;
  }

  .flex-basis-content {
    flex-basis: content;
  }
}

@layer components {
  .dataset-color {
  }
  .dataset-color.dataset-color-1 {
    @apply text-light-charts-dataset1 dark:text-dark-charts-dataset1;
  }
  .dataset-color.dataset-color-2 {
    @apply text-light-charts-dataset2 dark:text-dark-charts-dataset2;
  }
  .dataset-color.dataset-color-3 {
    @apply text-light-charts-dataset3 dark:text-dark-charts-dataset3;
  }
  .dataset-color.dataset-color-4 {
    @apply text-light-charts-dataset4 dark:text-dark-charts-dataset4;
  }
  .dataset-color.dataset-color-5 {
    @apply text-light-charts-dataset5 dark:text-dark-charts-dataset5;
  }
  .dataset-color.dataset-color-6 {
    @apply text-light-charts-dataset6 dark:text-dark-charts-dataset6;
  }
  .dataset-color.dataset-color-7 {
    @apply text-light-charts-dataset7 dark:text-dark-charts-dataset7;
  }
  .dataset-color.dataset-color-8 {
    @apply text-light-charts-dataset8 dark:text-dark-charts-dataset8;
  }
  .dataset-color.dataset-color-9 {
    @apply text-light-charts-dataset9 dark:text-dark-charts-dataset9;
  }
  .dataset-color.dataset-color-10 {
    @apply text-light-charts-dataset10 dark:text-dark-charts-dataset10;
  }
  .dataset-background {
    @apply h-6 w-6 rounded-lg mr-4 flex-grow-0 flex-shrink-0;
    flex-basis: 1.5rem;
  }
  .dataset-background.dataset-background-1 {
    @apply bg-light-charts-dataset1 dark:bg-dark-charts-dataset1;
  }
  .dataset-background.dataset-background-2 {
    @apply bg-light-charts-dataset2 dark:bg-dark-charts-dataset2;
  }
  .dataset-background.dataset-background-3 {
    @apply bg-light-charts-dataset3 dark:bg-dark-charts-dataset3;
  }
  .dataset-background.dataset-background-4 {
    @apply bg-light-charts-dataset4 dark:bg-dark-charts-dataset4;
  }
  .dataset-background.dataset-background-5 {
    @apply bg-light-charts-dataset5 dark:bg-dark-charts-dataset5;
  }
  .dataset-background.dataset-background-6 {
    @apply bg-light-charts-dataset6 dark:bg-dark-charts-dataset6;
  }
  .dataset-background.dataset-background-7 {
    @apply bg-light-charts-dataset7 dark:bg-dark-charts-dataset7;
  }
  .dataset-background.dataset-background-8 {
    @apply bg-light-charts-dataset8 dark:bg-dark-charts-dataset8;
  }
  .dataset-background.dataset-background-9 {
    @apply bg-light-charts-dataset9 dark:bg-dark-charts-dataset9;
  }
  .dataset-background.dataset-background-10 {
    @apply bg-light-charts-dataset10 dark:bg-dark-charts-dataset10;
  }
  .chart-loading-container {
    @apply flex items-center justify-center;
    aspect-ratio: 2/1;
  }

  .layout-sidenav {
    @apply h-full overflow-y-auto overflow-x-hidden fixed left-0 top-0 bottom-0 abs-w-0 md:abs-w-16;
    z-index: 1200;
    transform: translateX(0px);
    transition-property: min-width, transform, width;
    transition-duration: 0.14s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    background: linear-gradient(180deg, #046bd9 0%, #012961 100%);
  }
  .layout-sidenav.open-mobile {
    @apply w-full max-w-full min-w-full sm:abs-w-64 md:abs-w-16;
  }
  .layout-sidenav.hovered {
    @apply md:abs-w-64;
  }
  .layout-sidenav.touched {
    @apply md:abs-w-64;
  }
  .layout-sidenav.expanded {
    @apply md:abs-w-64 lg:sticky;
  }
  .layout-main.layout-main-light-bg {
    @apply bg-light-primary-lighter dark:bg-dark-primary-lighter;
  }
  .layout-main.layout-main-customers {
    @apply bg-white dark:bg-dark-primary-lighter;
  }
  .layout-main.layout-main-customer {
    @apply bg-light-neutral-light dark:bg-dark-primary-lighter;
  }
  .layout-main {
    @apply min-h-full box-border w-full flex flex-col items-stretch md:pl-16;
  }
  .layout-main:has(iframe) {
    line-height: 0;
  }
  .layout-main-inverted {
    @apply bg-light-secondary-lighter dark:bg-dark-secondary-lighter;
  }
  .layout-main.expanded {
    @apply lg:pl-0 lg:max-w-expanded;
  }
  .layout-top-nav-max-width {
    @apply max-w-full md:max-w-collapsed;
  }
  .layout-main.expanded .layout-top-nav-max-width {
    @apply lg:max-w-expanded;
  }
  .layout-main-max-width {
    @apply px-6 py-10 max-w-full lg:max-w-content-lg xl:max-w-content-xl;
  }
  .layout-main-full-width {
    @apply max-w-full lg:max-w-content-full;
  }
  .layout-primary-nav-item {
    @apply text-white h-12 box-border flex items-center justify-start rounded-lg no-underline mb-2 whitespace-nowrap;
    padding-left: 14px;
  }
  .layout-primary-nav-item:hover {
    background-color: rgba(239, 241, 243, 0.04);
  }
  .layout-primary-nav-item .icon {
  }
  .layout-primary-nav-item .label {
    @apply ml-4 text-sm flex-auto;
  }
  .layout-primary-nav-item .status {
    @apply mr-4;
  }
  .layout-primary-nav-item.active {
    background-color: rgba(11, 26, 38, 0.12);
  }

  .opt-in-button {
    @apply bg-transparent text-white cursor-pointer w-full border-0 h-12 box-border flex items-center justify-start rounded-lg no-underline mb-2 whitespace-nowrap;
    padding-left: 14px;
  }
  .layout-sidenav.hovered .opt-in-button,
  .layout-sidenav.expanded .opt-in-button,
  .layout-sidenav.hovered.expanded .opt-in-button,
  .layout-sidenav.open-mobile .opt-in-button {
    @apply bg-white text-light-primary-accent;
  }
  .opt-in-button:hover,
  .layout-sidenav.open-mobile .opt-in-button:hover {
    @apply bg-light-primary-lighter text-light-primary-accent;
  }
  .opt-in-button .label {
    @apply ml-4 text-sm;
  }
  .opt-out-button {
    @apply bg-transparent text-white cursor-pointer w-full border-0 h-12 box-border flex items-center justify-start rounded-lg no-underline mb-2 whitespace-nowrap;
    padding-left: 14px;
  }
  .opt-out-button:hover {
    background-color: rgba(239, 241, 243, 0.04);
  }
  .opt-out-button .label {
    @apply ml-4 text-sm;
  }

  .layout-secondary-nav-container {
    @apply overflow-hidden border-b-0 border-l-0 border-r-0 border-t border-light-neutral-accent border-solid;
  }
  .layout-secondary-nav-item {
    @apply text-white h-12 box-border w-full flex items-center justify-start rounded-lg no-underline pl-4 pr-3 mb-2 whitespace-nowrap border-none bg-transparent cursor-pointer;
  }
  .layout-secondary-sub-nav-item {
    @apply text-white h-8 box-border w-full hidden items-center justify-start rounded-lg no-underline pl-4 whitespace-nowrap border-none bg-transparent cursor-pointer;
  }
  .layout-secondary-sub-nav-item .label {
    @apply ml-8 text-xs font-normal leading-4;
  }
  .layout-secondary-nav-item.active {
    background-color: rgba(11, 26, 38, 0.12);
  }
  .layout-secondary-nav-item:hover,
  .layout-secondary-sub-nav-item:hover {
    background-color: rgba(239, 241, 243, 0.04);
  }
  .layout-secondary-nav-item .label {
    @apply ml-4 text-xs text-left flex-1 flex w-full items-center justify-between;
  }
  .layout-primary-nav-item .arrow {
  }
  .layout-secondary-nav-submenu {
    @apply overflow-hidden mb-2 -mt-2;
    height: 0px;
    transform: translateY(0px);
    transition-property: transform, height;
    transition-duration: 0.14s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .layout-secondary-nav-submenu.expanded {
    height: auto;
  }
  .layout-secondary-nav-submenu.expanded > .layout-secondary-sub-nav-item {
    @apply flex;
  }
  .layout-sidenav:not(.hovered, .touched, .open-mobile, .expanded)
    .layout-secondary-nav-submenu.expanded {
    height: 0px;
  }
  .layout-user {
    @apply flex items-center justify-between p-3;
  }
  .layout-user .layout-user-details {
    @apply flex-grow whitespace-nowrap ml-3 flex flex-col text-white;
    max-width: calc(100% - 84px);
  }
  .layout-user .layout-user-details .marketplace {
    @apply text-sm truncate;
  }
  .layout-user .layout-user-details .plan {
    @apply text-dark-text-secondary truncate;
  }
  .layout-user .layout-user-details .menu {
    @apply text-white;
  }
  .mobile-menu-button {
    @apply bg-transparent h-10 w-10 border-none flex items-center justify-center cursor-pointer ml-3;
  }
  .layout-mobile-top-nav {
    @apply fixed z-40 top-14 left-0 right-0 px-6 h-14 bg-light-secondary-lighter dark:bg-dark-secondary-lighter flex items-center justify-start;
    transition-property: opacity translateY;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
  }
  .layout-mobile-top-nav.not-scrolled {
    @apply opacity-100;
    transform: translateY(0px);
  }
  .layout-mobile-top-nav.scrolled {
    @apply opacity-0;
    transform: translateY(-24px);
  }

  .layout-main.layout-main-apps {
    @apply bg-light-secondary-lighter dark:bg-dark-secondary-lighter;
  }
  .layout-main-apps .app-page-header {
    @apply bg-light-background-default dark:bg-dark-background-default pb-6 pt-36 md:pt-24 -mb-36 md:-mb-24 border-b border-t-0 border-x-0 border-solid border-b-light-neutral-accent dark:border-b-dark-neutral-accent;
  }
  .layout-main-apps .app-page-header .app-page-header-contents {
    @apply px-6 max-w-full lg:max-w-content-lg xl:max-w-content-xl;
  }

  .settings-layout {
    @apply relative;
  }
  .settings-layout .settings-layout-header {
    @apply z-30 h-12 md:h-[4.5rem] fixed right-0 top-0 left-0 flex bg-light-background-default dark:bg-dark-background-default items-center p-2 md:px-6 md:py-4 border-0 border-b border-b-light-neutral-accent dark:border-b-dark-neutral-accent border-solid;
  }
  .settings-layout .settings-layout-top-nav {
    @apply md:hidden relative h-24 mt-12 px-6 py-3 bg-light-secondary-lighter dark:bg-dark-secondary-lighter border-0 border-b border-b-light-neutral-accent dark:border-b-dark-neutral-accent border-solid;
  }
  .settings-layout .settings-layout-body {
    @apply flex relative md:mt-[4.5rem];
  }
  .settings-layout .settings-layout-side-nav {
    @apply hidden md:block md:fixed left-0 bottom-0 top-[4.5rem] p-6 bg-light-secondary-lighter dark:bg-dark-secondary-lighter w-64 border-0 border-r border-r-light-neutral-accent dark:border-r-dark-neutral-accent border-solid;
  }
  .settings-layout .settings-layout-main {
    @apply flex-auto relative md:ml-64;
  }
  .settings-layout .settings-layout-main.settings-layout-main-full-width {
    @apply max-w-full lg:max-w-content-full;
  }
  .settings-layout .settings-layout-main.settings-layout-main-max-width {
    @apply px-6 py-10 max-w-full lg:max-w-content-lg xl:max-w-content-xl;
  }
  .settings-layout .settings-layout-main.settings-layout-height-desktop {
    height: calc(100vh - 5rem);
  }
  .settings-layout .settings-layout-main.settings-layout-height-mobile {
    height: calc(100vh - 9.5rem);
  }

  .auth-marketing {
    @apply py-6 hidden justify-center items-center flex-col md:flex md:min-h-screen bg-[#001134] shadow-auth-marketing-light dark:shadow-auth-marketing-dark;
  }
  .auth-marketing img {
    @apply h-auto w-full;
  }

  .objectfit-contain {
    object-fit: contain;
  }

  .grey-text {
    @apply text-light-text-secondary dark:text-dark-text-secondary;
  }
  .error-text {
    @apply text-light-error-main dark:text-dark-error-main;
  }

  .stretchable-card > div > div,
  .stretchable-card > div,
  .stretchable-card {
    @apply flex flex-col h-full w-full;
  }

  .background-color-transition {
    transition: background-color 0.25s ease;
  }
  .dashboard-card {
    @apply bg-light-background-default dark:bg-dark-background-default p-4 sm:p-6 rounded-lg shadow-light-1 dark:shadow-dark-1;
  }
  .recent-activity-height {
    max-height: calc(100vh - 10rem);
  }
  .recent-activity-content-height {
    max-height: calc(100vh - 15.5rem);
  }
  .recent-activity-card {
    @apply h-[324px] md:h-[432px] lg:h-auto lg:recent-activity-height;
  }
  .recent-activity-content {
    @apply overflow-y-auto overflow-x-hidden pr-6 -mr-4 sm:-mr-6 h-[244px] md:h-[336px] lg:h-auto lg:recent-activity-content-height;
  }
  .backface-hidden {
    backface-visibility: hidden;
  }
  .rotate-y-180,
  .hovering {
    transform: rotateY(180deg);
  }

  .min-h-78 {
    min-height: 78px;
  }

  .app-card {
    @apply bg-transparent;
  }
  .app-card .MuiCardActionArea-root {
    @apply h-full;
  }

  .branding-settings-page .layout-main-max-width {
    @apply max-w-content-xl;
  }

  .branding-settings-wrapper {
    @apply -my-10 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3;
    min-height: calc(100% + 5rem);
  }
  .branding-settings-col1 {
    @apply py-6 md:pr-6 col-span-1 border-0 md:border-r md:border-solid md:border-r-light-neutral-accent dark:md:border-r-dark-neutral-accent;
  }
  .branding-settings-col2 {
    @apply md:col-span-1 lg:col-span-2 flex justify-center;
  }

  .Mui-disabled .branding-override-toggle-link {
    @apply text-inherit pointer-events-none;
  }

  .page-type-icon {
    @apply rounded-full w-10 h-10 flex items-center justify-center basis-10 shrink-0;
  }
  .page-type-icon svg {
    @apply text-white dark:text-grey-800;
  }
  .page-type-icon.page-type-sales-page {
    @apply bg-light-success-main dark:bg-dark-success-main;
  }
  .page-type-icon.page-type-landing-page {
    @apply bg-light-highlight-main dark:bg-dark-highlight-main;
  }
  .page-type-icon.page-type-lead-capture {
    @apply bg-light-spotlight-main dark:bg-dark-spotlight-main;
  }
  .page-type-icon.page-type-link-in-bio {
    @apply bg-light-primary-main dark:bg-dark-primary-main;
  }

  .page-settings-header {
    @apply fixed top-14 md:top-0 z-50 h-14 md:h-16 w-full md:border-b border-t-0 border-l-0 border-r-0 md:border-solid md:bg-light-background-default md:dark:bg-dark-background-default md:border-light-neutral-accent md:dark:border-dark-neutral-accent;
  }
  .page-settings-header .page-type-icon {
    @apply w-8 h-8 basis-8;
  }
  .page-settings-header .page-type-icon svg {
    @apply w-5 h-5;
  }

  .recently-updated-page .page-type-icon {
    @apply w-6 h-6 basis-6;
  }
  .recently-updated-page .page-type-icon svg {
    @apply w-3 h-3;
  }

  .domain-card {
    @apply flex w-full border-none bg-transparent justify-start text-left p-4 cursor-pointer rounded-lg items-center;
  }
  .domain-card:hover {
    @apply bg-light-primary-lighter dark:bg-dark-primary-lighter;
  }
  .domain-card.selected {
    @apply bg-light-primary-light dark:bg-dark-primary-light;
  }

  .strong-ordered-list {
    @apply mb-6 list-none pl-0;
  }
  .strong-ordered-list li {
    @apply mb-6 flex items-center;
  }
  .strong-ordered-list li .marker {
    @apply mx-3 flex items-center justify-center text-xs font-medium rounded-full w-5 h-5 bg-light-primary-main dark:bg-dark-primary-main text-light-primary-contrastText dark:text-dark-primary-contrastText;
  }
}

#fc_frame {
  z-index: 1000 !important;
}

.stripe-border {
  border: 1px solid;
}
.stripe-border-l {
  border-left: 1px solid;
}
.stripe-border-b {
  border-bottom: 1px solid;
}

.slide-alert .sc-content {
  width: 100%;
}

.global-search .sc-drawer-surface {
  @apply shadow-none sm:bg-transparent h-screen max-h-screen min-h-screen w-full sm:max-w-2xl sm:my-28 sm:mx-auto sm:h-auto sm:min-h-0 sm:max-h-[calc(100vh-224px)];
}
.global-search .sc-drawer-surface .sc-drawer-container {
  @apply p-0 sm:bg-transparent;
}
.global-search .global-search-container {
  @apply sm:px-6;
}
.global-search .global-search-field {
  @apply px-6 py-2 border-light-neutral-accent dark:border-dark-neutral-accent border-b border-t-0 border-r-0 border-l-0 border-solid bg-light-secondary-lighter dark:bg-dark-secondary-lighter sm:px-0 sm:py-0 sm:bg-transparent sm:border-none;
}
.global-search .global-search-input-container {
  @apply h-12 sm:shadow-light-3 sm:dark:shadow-dark-3;
}
.global-search .global-search-input-container .global-search-input {
  @apply py-3;
}
.global-search .global-search-results-container {
  @apply max-h-[calc(100vh-130px)] sm:max-h-[calc(100vh-280px)] overflow-y-auto sm:mt-2 py-4 px-6 sm:shadow-light-3 sm:dark:shadow-dark-3 sm:rounded-lg bg-light-background-default dark:bg-dark-background-default;
}
.global-search .global-search-result {
  @apply flex items-center border-none bg-transparent w-full px-6 py-1 cursor-pointer hover:bg-light-primary-lighter dark:hover:bg-dark-primary-lighter;
}

.paywall-scaler {
  @apply p-0 h-full bg-no-repeat;
}

.paywall-scaler.cart-abandonment {
  @apply bg-[url('https://static.samcart.com/dashboard/scaler-paywall/paywall-bg-xs-placeholder.png')] sm:bg-[url('https://static.samcart.com/dashboard/scaler-paywall/paywall-bg-sm-placeholder.png')] md:bg-[url('https://static.samcart.com/dashboard/scaler-paywall/paywall-bg-md-placeholder.png')] lg:bg-[url('https://static.samcart.com/dashboard/scaler-paywall/paywall-bg-lg-placeholder.png')] xl:bg-[url('https://static.samcart.com/dashboard/scaler-paywall/paywall-bg-xl-placeholder.png')];
}

.paywall-scaler-content {
  @apply flex items-center justify-center h-full bg-light-neutral-medium bg-opacity-50;
}

.layout-main-full-width:has(.paywall-scaler),
.layout-main-max-width:has(.paywall-scaler) {
  @apply p-0;
}
