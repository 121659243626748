.fontpicker,
.fontpicker * {
  box-sizing: border-box;
}

.MuiAccordionDetails-root .fontpicker {
  font-weight: 400;
  font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;
  font-size: 1rem;
  line-height: 1.4375em;
  color: #233543;
  box-sizing: border-box;
  cursor: text;
  align-items: center;
  position: relative;
  border-radius: 8px;
  background: unset;
  height: 40px;
  border: 0;
}

.fontpicker__preview {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.MuiAccordionDetails-root .fontpicker__search {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  padding: 0 10px;
  cursor: pointer;
  font-size: 16px;
  background: unset;
  border: 1px solid rgb(156, 171, 183);
  border-radius: 8px;
}

.MuiAccordionDetails-root .fontpicker__search:focus {
  cursor: text;
  opacity: 1;
  outline: none;
  border-color: #024291;
  border-radius: 8px 8px 0 0;
}

.MuiAccordionDetails-root .fontpicker__popout {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: calc(12em + 1px);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 2;
  background: unset;
  opacity: 0;
  transform: scaleY(0.001);
  border: 1px solid #024291;
  border-radius: 0 0 8px 8px;
  border-top: 0;
}

.fontpicker__popout.fontpicker__active {
  opacity: 1;
  transform: scale(1);
}

.MuiAccordionDetails-root .fontpicker__option {
  background: #ffffff;
  cursor: pointer;
  padding: 4px 0;
}

.MuiAccordionDetails-root .fontpicker__option.selected {
  background: #f5f8fa;
}

.MuiAccordionDetails-root .fontpicker__nomatches {
  height: 2em;
  line-height: 2em;
  background: #fff;
  text-align: center;
  color: #333;
}
